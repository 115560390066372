<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card :title="data.vendorName+' 정보'" class="cardClassDetailForm">
            <template slot="card-description">
              <font color="#C10015">
                ※ 최초 생성한 사용자 비밀번호는 사업자번호입니다. 비밀번호를 모를 시 초기화 버튼을 눌리세요.
              </font>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="resetUrl"
                  :isSubmit="isReset"
                  :param="data"
                  mappingType="PUT"
                  label="관리자 비밀번호 초기화"
                  icon="password"
                  @beforeAction="saveReset"
                  @btnCallback="saveResetCallback" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  mappingType="PUT"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="false"
                  label="업체명"
                  name="vendorName"
                  v-model="data.vendorName">
                </c-text>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="사업자번호"
                  name="bizNo"
                  mask="##########"
                  v-model="data.bizNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-select
                  codeGroupCd="VENDOR_CLASS_CD"
                  :required="true"
                  :editable="editable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="vendorClassCd"
                  label="업체분류"
                  v-model="data.vendorClassCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-select
                  codeGroupCd="VENDOR_TYPE_CD"
                  :required="true"
                  :editable="editable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="vendorTypeCd"
                  label="업체유형"
                  v-model="data.vendorTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="대표자명"
                  name="representName"
                  v-model="data.representName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="대표전화번호"
                  name="phoneNo"
                  v-model="data.phoneNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="대표이메일"
                  name="email"
                  v-model="data.email">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <c-text
                  :editable="editable"
                  label="본사주소"
                  name="address"
                  v-model="data.address">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="담당자명"
                  name="chargeName"
                  v-model="data.chargeName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="false"
                  label="관리자 접속 ID"
                  name="vendorLoginId"
                  v-model="data.vendorLoginId">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="vendorUserTable"
          title="업체 근무인원"
          tableId="vendorUserTable"
          :columns="grid2.columns"
          :data="data.vendorUserList"
          gridHeight="455px"
          :editable="editable"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          selection="multiple"
          rowKey="userId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow" />
              <c-btn v-if="editable" label="제외" icon="remove" @btnClicked="removerow" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'loginId'">
              <span v-if="props.row['dayJobFlag'] === 'Y'">
              </span>
              <span v-else>
                {{props.row['loginId']}}
              </span>
            </template>
            <template v-if="col.name === 'resetPw'">
              <q-btn
                v-if="props.row['editFlag'] !== 'C' && props.row['dayJobFlag'] !== 'Y'"
                class="glossy"
                round 
                size="sm"
                :editable="editable"
                color="blue-6"
                icon="password"
                @click.stop="saveReset2(props.row)" />
            </template>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-self-manage',
  data() {
    return {
      searchParam: {
        vendorTypeCd: null,
        vendorClassCd: null,
        vendorName: '',
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '업체명',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'vendorClassName',
            field: 'vendorClassName',
            label: '업체분류',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorTypeName',
            field: 'vendorTypeName',
            label: '업체유형',
            align: 'center',
            sortable: true,
          },
          {
            name: 'representName',
            field: 'representName',
            label: '대표자명',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      grid2: {
        columns: [],
        data: [],
      },
      data: {
        companyCd: '',  // 회사코드
        afterCnt: '',
        vendorCd: '',  // 업체코드
        vendorName: '',  // 업체명
        vendorClassCd: null,  // 업체분류 코드
        vendorTypeCd: null,  // 업체유형 코드
        address: '',  // 본사주소
        bizNo: '',  // 사업자번호
        phoneNo: '',  // 대표 전화번호
        email: '',  // 대표 이메일
        chargeName: '',  // 담당자명
        representName: '',  // 대표자명
        companyFlag: 'N',  // 본사여부
        portalId: '',  // 포탈 ID
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendorUserList: [],
        deleteVendorUserList: [],
      },
      resetUrl: '',
      isReset: false,
      editable: true,
      detailUrl: '',
      isSave: false,
      saveUrl: transactionConfig.mdm.cim.vendor.selfUpdate.url,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.detailUrl = selectConfig.mdm.cim.vendor.get.url;
      this.resetUrl = transactionConfig.mdm.cim.vendor.reset.url;

      this.setHeader();
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.detailUrl, this.$store.getters.user.deptCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
      },);
    },
    setHeader() {
      this.$comm.getComboItems('SEX_CD').then(sexFlag => {
        this.$comm.getComboItems('JOB_CD').then(jobFlag => {
          this.$comm.getComboItems('SPOT_CD').then(spotFlag => {
            this.$comm.getComboItems('SAFETY_VENDOR_ORG_ROLE').then(roleFlag => {
              this.grid2.columns = [
                {
                  required: true,
                  name: 'userName',
                  field: 'userName',
                  label: '성명',
                  align: 'center',
                  sortable: false,
                  style: 'width:100px',
                  type: 'text',
                },
                {
                  name: 'projectRoleCd',
                  field: 'projectRoleCd',
                  valueName: 'codeName',
                  label: '역할',
                  align: 'center',
                  style: "width:100px",
                  sortable: false,
                  type: 'select',
                  comboItems: roleFlag,
                },
                {
                  name: 'jobCd',
                  field: 'jobCd',
                  label: '직책',
                  align: 'center',
                  style: 'width:100px',
                  sortable: false,
                  valueName: 'codeName',
                  itemText: 'codeName',
                  itemValue: 'code',
                  type: 'select',
                  comboItems: jobFlag,
                },
                {
                  name: 'spotCd',
                  field: 'spotCd',
                  label: '직위',
                  align: 'center',
                  style: 'width:80px',
                  sortable: false,
                  valueName: 'codeName',
                  itemText: 'codeName',
                  itemValue: 'code',
                  type: 'select',
                  comboItems: spotFlag,
                },
                {
                  required: true,
                  name: 'birthDate',
                  field: 'birthDate',
                  label: '생년월일',
                  style: 'width:130px',
                  type: 'date',
                  align: 'center',
                  sortable: false,
                },
                {
                  name: 'sexCd',
                  field: 'sexCd',
                  label: '성별',
                  align: 'center',
                  style: 'width:80px',
                  sortable: false,
                  valueName: 'codeName',
                  itemText: 'codeName',
                  itemValue: 'code',
                  type: 'select',
                  comboItems: sexFlag,
                },
                {
                  name: 'mobileNo',
                  field: 'mobileNo',
                  label: '연락처',
                  align: 'left',
                  style: 'width:180px',
                  type: 'text',
                  sortable: false,
                },
                {
                  name: 'email',
                  field: 'email',
                  label: '이메일',
                  align: 'left',
                  style: 'width:180px',
                  type: 'text',
                  sortable: false,
                },
                {
                  name: 'dayJobFlag',
                  field: 'dayJobFlag',
                  label: '일용직여부',
                  align: 'center',
                  sortable: true,
                  style: 'width:80px',
                  type: 'check',
                  true: 'Y',
                  false: 'N',
                },
                {
                  name: 'loginId',
                  field: 'loginId',
                  label: '로그인ID',
                  align: 'center',
                  type: 'custom',
                  style: 'width:100px',
                  sortable: false,
                },
                {
                  name: 'resetPw',
                  field: 'resetPw',
                  label: '비밀번호초기화',
                  align: 'center',
                  style: 'width:100px',
                  type: 'custom',
                  sortable: false,
                },
              ];
            });
          });
        });
      });
    },
    addrow() {
      // 초기에는 userId에 tempId를 부여
      this.data.vendorUserList.splice(0, 0, {
        companyCd: this.$store.getters.user.companyCd,
        userId: uid(),
        empNo: '',
        userName: '',
        loginId: '',
        loginPwd: this.data.bizNo,
        deptCd: this.data.vendorCd,
        sexCd: null,
        mobileNo: '',
        birthDate: '',
        email: '',
        jobCd: null,
        spotCd: null,
        retireFlag: 'N',
        projectRole: null,
        retireDate: '',
        managerFlag: 'N',
        dayJobFlag: 'Y',
        deleteFlag: 'N',  // 삭제여부
        innerFlag: 'N',
        editFlag: 'C'
      })
    },
    removerow() {
      let selectData = this.$refs['vendorUserTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.data.deleteVendorUserList) {
            this.data.deleteVendorUserList = [];
          }
          if (
            this.$_.findIndex(this.data.deleteVendorUserList, {
              userId: item.userId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.data.deleteVendorUserList.push(item);
          }

          this.data.vendorUserList = this.$_.reject(this.data.vendorUserList, item);
        })
        this.$refs['vendorUserTable'].$refs['compo-table'].clearSelection();
      }
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.companyCd = this.$store.getters.user.companyCd
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    saveReset() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '관리자 비밀번호를 사업자번호로 초기화 하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.chgUserId = this.$store.getters.user.userId
          this.isReset = !this.isReset;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveResetCallback() {
      window.getApp.$emit('ALERT', {
        title: '안내', // 안내
        message:
        '초기비밀번호(사업자번호)로 초기화 되었습니다.',
        type: 'success', // success / info / warning / error
      });
    },
    saveReset2(data) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '사용자 비밀번호를 사업자번호로 초기화 하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.resetUrl;
          this.$http.type = 'PUT';
          this.$http.param = {
            bizNo: this.data.bizNo,
            chgUserId: this.$store.getters.user.userId,
            vendorCd: this.data.vendorCd,
            vendorLoginId: data.loginId,
            loginPwd: '',
          }
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message:
              '초기비밀번호(사업자번호)로 초기화 되었습니다.',
              type: 'success', // success / info / warning / error
            });
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
