var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: _vm.data.vendorName + " 정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-description" },
                    [
                      _c("font", { attrs: { color: "#C10015" } }, [
                        _vm._v(
                          " ※ 최초 생성한 사용자 비밀번호는 사업자번호입니다. 비밀번호를 모를 시 초기화 버튼을 눌리세요. "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.resetUrl,
                                  isSubmit: _vm.isReset,
                                  param: _vm.data,
                                  mappingType: "PUT",
                                  label: "관리자 비밀번호 초기화",
                                  icon: "password",
                                },
                                on: {
                                  beforeAction: _vm.saveReset,
                                  btnCallback: _vm.saveResetCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: "PUT",
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveData,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "사업자번호",
                            name: "bizNo",
                            mask: "##########",
                          },
                          model: {
                            value: _vm.data.bizNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "bizNo", $$v)
                            },
                            expression: "data.bizNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "VENDOR_CLASS_CD",
                            required: true,
                            editable: _vm.editable,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "vendorClassCd",
                            label: "업체분류",
                          },
                          model: {
                            value: _vm.data.vendorClassCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "vendorClassCd", $$v)
                            },
                            expression: "data.vendorClassCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "VENDOR_TYPE_CD",
                            required: true,
                            editable: _vm.editable,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "vendorTypeCd",
                            label: "업체유형",
                          },
                          model: {
                            value: _vm.data.vendorTypeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "vendorTypeCd", $$v)
                            },
                            expression: "data.vendorTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "대표자명",
                            name: "representName",
                          },
                          model: {
                            value: _vm.data.representName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "representName", $$v)
                            },
                            expression: "data.representName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "대표전화번호",
                            name: "phoneNo",
                          },
                          model: {
                            value: _vm.data.phoneNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "phoneNo", $$v)
                            },
                            expression: "data.phoneNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "대표이메일",
                            name: "email",
                          },
                          model: {
                            value: _vm.data.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "email", $$v)
                            },
                            expression: "data.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "본사주소",
                            name: "address",
                          },
                          model: {
                            value: _vm.data.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "address", $$v)
                            },
                            expression: "data.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "담당자명",
                            name: "chargeName",
                          },
                          model: {
                            value: _vm.data.chargeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "chargeName", $$v)
                            },
                            expression: "data.chargeName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "관리자 접속 ID",
                            name: "vendorLoginId",
                          },
                          model: {
                            value: _vm.data.vendorLoginId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "vendorLoginId", $$v)
                            },
                            expression: "data.vendorLoginId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "사용여부",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "vendorUserTable",
              attrs: {
                title: "업체 근무인원",
                tableId: "vendorUserTable",
                columns: _vm.grid2.columns,
                data: _vm.data.vendorUserList,
                gridHeight: "455px",
                editable: _vm.editable,
                usePaging: false,
                hideBottom: true,
                columnSetting: false,
                selection: "multiple",
                rowKey: "userId",
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "loginId"
                        ? [
                            props.row["dayJobFlag"] === "Y"
                              ? _c("span")
                              : _c("span", [
                                  _vm._v(
                                    " " + _vm._s(props.row["loginId"]) + " "
                                  ),
                                ]),
                          ]
                        : _vm._e(),
                      col.name === "resetPw"
                        ? [
                            props.row["editFlag"] !== "C" &&
                            props.row["dayJobFlag"] !== "Y"
                              ? _c("q-btn", {
                                  staticClass: "glossy",
                                  attrs: {
                                    round: "",
                                    size: "sm",
                                    editable: _vm.editable,
                                    color: "blue-6",
                                    icon: "password",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.saveReset2(props.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "추가", icon: "add" },
                            on: { btnClicked: _vm.addrow },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "제외", icon: "remove" },
                            on: { btnClicked: _vm.removerow },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }